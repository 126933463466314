import React from 'react';

import { useGetLegionInstructionTextByLanguage } from '@widgets/legions/hooks/useGetLegionInstructionTextByLanguage';
import QuestionLayout from '@widgets/questions-answers/QuestionLayout';

import bronzeBadge from '@assets/images/badges/bronze.jpg';
import silverBadge from '@assets/images/badges/silver.jpg';
import goldBadge from '@assets/images/badges/gold.jpg';
import diamantBadge from '@assets/images/badges/diamant.jpg';
import sapphireBadge from '@assets/images/badges/sapphire.jpg';

interface QuestionPageProps {
  location: any;
}

const LegionInstructionQuestionPage: React.FC<QuestionPageProps> = ({ location }) => {
  const t = useGetLegionInstructionTextByLanguage();

  const question = 'legion-instruction-question-5';

  return (
    <QuestionLayout location={location} question={question}>
      <ul className="text-xs space-y-3">
        <li className="flex items-center space-x-[10px]">
          <img className="w-[45px] rounded-full" src={bronzeBadge} alt="Бронзова нагорода легіонера" />
          <span>- {t('legion-instruction-bronze')}.</span>
        </li>

        <li className="flex items-center space-x-[10px]">
          <img className="w-[45px] rounded-full" src={silverBadge} alt="Срібна нагорода легіонера" />
          <span>- {t('legion-instruction-silver')}.</span>
        </li>

        <li className="flex items-center space-x-[10px]">
          <img className="w-[45px] rounded-full" src={goldBadge} alt="Золота нагорода легіонера" />
          <span>- {t('legion-instruction-gold')}.</span>
        </li>

        <li className="flex items-center space-x-[10px]">
          <img className="w-[45px] rounded-full" src={sapphireBadge} alt="Сапфірова нагорода легіонера" />
          <span>- {t('legion-instruction-sapphire')}.</span>
        </li>

        <li className="flex items-center space-x-[10px]">
          <img className="w-[45px] rounded-full" src={diamantBadge} alt="Діамантова нагорода легіонера" />
          <span>- {t('legion-instruction-diamant')}.</span>
        </li>
      </ul>
    </QuestionLayout>
  );
};

export default LegionInstructionQuestionPage;
